import React from 'react'
import PropTypes from 'prop-types'

import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import Footer from './Footer'
import Helmet from 'react-helmet'

class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuVisible: false,
            loading: 'is-loading'
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this)
    }

    componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleToggleMenu() {
        this.setState({
            isMenuVisible: !this.state.isMenuVisible
        })
    }

    render() {
        const { children } = this.props

        return (
            <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
                <Helmet>
                    <script>{`
                        (function(f, a, t, h, o, m){
                            a[h]=a[h]||function(){
                                (a[h].q=a[h].q||[]).push(arguments)
                            };
                            o=f.createElement('script'),
                            m=f.getElementsByTagName('script')[0];
                            o.async=1; o.src=t; o.id='fathom-script';
                            m.parentNode.insertBefore(o,m)
                        })(document, window, '//stats.lobster-writer.co.uk/tracker.js', 'fathom');
                        fathom('set', 'siteId', 'PANUE');
                        fathom('trackPageview');
                        `}
                    </script>
                    <script data-ad-client="ca-pub-4963578071993754" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                </Helmet>
                <div id="wrapper">
                    {children}
                    <Contact />
                    <Footer />
                </div>
                <Menu onToggleMenu={this.handleToggleMenu} />
            </div>
        )
    }
}

export default Layout
