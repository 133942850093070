import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import logoImage from '../assets/images/logo-horizontal-white.png';

const Header = (props) => (
    <header id="header" className="alt">
        <Link to="/" className="logo"><img src={logoImage}/></Link>
    </header>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
